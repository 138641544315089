.beakLeft, .beakRight, .beakUp, .beakDown {
    width: 0;
    height: 0;
    margin: -1px;
}

.beakLeft, .beakRight {
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
}

.beakLeft {
    border-right: 40px solid rgba(97, 97, 97);
}

.beakRight {
    border-left: 40px solid rgba(97, 97, 97);
}

.beakUp, .beakDown {
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
}

.beakUp {
    border-bottom: 40px solid rgba(97, 97, 97);
}

.beakDown {
    border-top: 40px solid rgba(97, 97, 97);
}