body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
}

html, #root {
    width: 100%;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*leaflet*/
.leaflet-popup-tip-container {
    display: none;
}

.leaflet-popup-close-button {
    display: none;
}

.leaflet-div-icon {
    background: transparent !important;
    border: none !important;
}

.leaflet-popup-content-wrapper {
    box-shadow: none !important;
    border-radius: 0px !important;
    padding: 0px !important;
}

.leaflet-popup-content {
    margin: 14px -1px !important;
    width: 0 !important;
    line-height: inherit !important;
}

.leaflet-popup-content p {
    margin: 4px !important;
}

.marker-cluster-custom {
    background: #9370db;
    border: 3px solid #ededed;
    border-radius: 50%;
    color: #ededed;
    height: 40px;
    line-height: 37px;
    text-align: center;
    width: 40px;
}

.leaflet-tooltip {
    box-shadow: none !important;
    background-color: rgba(97, 97, 97, 0.0) !important;
    border-radius: 4px !important;
    border: 0 !important;
    padding: 0 !important;
    opacity: 1 !important;

}

.leaflet-container {
    font-family: inherit !important;
}
.leaflet-tooltip:before {
    display: none !important;
}

/*scrollbars*/
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #18202c;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: #18202c;
}

::-webkit-scrollbar-thumb {
    background-color: #757575;
}