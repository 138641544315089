.react-date-picker__calendar--open {
    z-index: 2;
}


.MuiInput-underline.react-date-picker--open:before {
    border-bottom: 2px solid #1565c0 !important;
}

.DateRangeField-outline .MuiInput-underline:before {
    display: none !important;
}


.react-date-picker__wrapper {
    border: unset;
}

.react-date-picker__inputGroup__input {
    color: white;
    outline: none;
}

.react-calendar__navigation__label,
.react-calendar__navigation__arrow,
.react-calendar__tile {
    color: white;
}


.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
}

.react-date-picker {
    padding-left: 1em;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus,
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    color: white;
    background-color: rgba(255, 255, 255, 0.08);
}

.react-calendar__tile.react-calendar__tile--now {
    background-color: unset;
    border: 1px solid white;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active,
.react-calendar__tile--active {
    background-color: #1565c0;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.react-calendar__tile--active:hover,
.react-calendar__tile--active:focus {
    background-color: #1565c0 !important;
}

.react-calendar {
    border: none;
}
