
.loadingRotation {

    animation: rotation 2s infinite ease-in-out;
}

.loadingHidden {
    visibility: hidden;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}